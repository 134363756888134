let _OPTIONS = {
    "teamType": [
        {"label": "个人", "value": 1, "checked": 0, "disabled": true},
        {"label": "企业", "value": 2, "checked": 0, "disabled": false},
        {"label": "政务", "value": 3, "checked": 0, "disabled": false},
        {"label": "学校", "value": 4, "checked": 0, "disabled": false},
    ],
    "adminRole": [
        {"label": "系统", "value": 1, "checked": 0, "disabled": true},
        {"label": "团队负责人", "value": 2, "checked": 0, "disabled": false},
        {"label": "普通管理员", "value": 10, "checked": 0, "disabled": false},
    ],
    "adminLevel": [
        /*{"label": "ROOT", "value": 1, "checked": 0, "disabled": true},
        {"label": "系统管理员", "value": 2, "checked": 0, "disabled": true},*/
        {"label": "超级管理员", "value": 3, "checked": 0, "disabled": false},
        {"label": "高级管理员", "value": 4, "checked": 0, "disabled": false},
        {"label": "普通管理员", "value": 5, "checked": 1, "disabled": false},
    ],
    "articleAttr": {
        "0": "普通",
        "1": "推荐",
        "2": "置顶",
        "3": "头条"
    },
    "printType": [
        {"label": "【登记表】就读学校对本人在校期间表现及文化程度鉴定意见", "value": "prerequisit_wenhua", "checked": 0},
        {"label": "【登记表】兵役机关对本人是否确定为预征对象结论意见", "value": "prerequisit_jiguanyijian", "checked": 0},
        {"label": "【考核表】初步政治考核意见", "value": "examine_chubu", "checked": 0},
        {"label": "【考核表】联合政治考核意见", "value": "examine_lianhe", "checked": 0},
        {"label": "【考核表】走访调查意见", "value": "examine_zoufang", "checked": 0},
        {"label": "【考核表】公安机关、兵役 机关政治考核意见", "value": "examine_jiebingzoufang", "checked": 0},
        {"label": "【考核表】机关政治考核意见", "value": "examine_paichusuo", "checked": 0},
        {"label": "【考核表】政治考核结论", "value": "examine_jielun", "checked": 0}
    ],
    "attentionType": [
        {"label": "本人主要经历", "value": "1", "checked": 0},
        {"label": "本人受奖惩情况", "value": "2", "checked": 0},
        {"label": "本人出国(境)情况", "value": "3", "checked": 0},
        {"label": "家庭成员情况", "value": "4", "checked": 0},
        {"label": "配偶的父母、未共同生活的兄弟姐妹情况", "value": "5", "checked": 0},
        {"label": "本人、家庭成员移居国(境)外情况", "value": "6", "checked": 0},
        {"label": "家庭成员、配偶的父母、为共同生活的兄弟姐妹受纪律处分和刑事处罚等境况", "value": "7", "checked": 0},
    ],
    "sex": [
        {"label": "男", "value": 1, "checked": 0},
        {"label": "女", "value": 2, "checked": 0}
    ],
    "organization": [
        {
            label: "河南省军区",
            code: "410000",
            value: '410000',
            pid: 0,
            position: '113.749558,34.768497',
            children: [
                {
                    value: 410105,
                    pid: 410000,
                    label: '金水区人武部(郑东新区)',
                    position: '113.6603,34.800415',
                    children: [
                        {value: 3, label: '花园路街道办事处'},
                        {value: 4, label: '文化路街道办事处'},
                        {value: 3, label: '文化路街道办事处'},
                        {value: 3, label: '南阳新村街道办事处'},
                        {value: 3, label: '经八路街道办事处'},
                        {value: 3, label: '大石桥街道办事处'},
                        {value: 3, label: '人民路街道办事处'},
                        {value: 3, label: '杜岭街街道办事处'},
                        {value: 3, label: '丰产路街道办事处'},
                        {value: 3, label: '北林路街道办事处'},
                        {value: 3, label: '未来路街道办事处'},
                        {value: 3, label: '东风路街道办事处'},
                        {value: 3, label: '凤凰台街道办事处'},
                        {value: 3, label: '兴达路街道办事处'},
                        {value: 3, label: '南阳路街道办事处'},
                        {value: 3, label: '丰产路街道办事处'},
                        {value: 3, label: '国基路街道办事处'},
                        {value: 3, label: '杨金路街道办事处'},
                        {value: 3, label: '如意湖街道办事处'},
                        {value: 3, label: '商都路街道办事处'},
                        {value: 3, label: '祭城路街道办事处'},
                        {value: 3, label: '博学路街道办事处'},
                        {value: 3, label: '龙子湖街道办事处'},
                    ]
                },
                {
                    value: 410103,
                    pid: 410000,
                    label: '二七区人武部',
                    position: '113.640177,34.724138',
                    children: [
                        {value: 3, label: '一马路街道办事处'},
                        {value: 3, label: '大学路街道办事处'},
                        {value: 3, label: '建中街街道办事处'},
                        {value: 3, label: '解放路街道办事处'},
                        {value: 3, label: '福华街街道办事处'},
                        {value: 3, label: '淮河路街道办事处'},
                        {value: 3, label: '五里堡街道办事处'},
                        {value: 3, label: '蜜蜂张办事处'},
                        {value: 3, label: '铭功路街道办事处'},
                        {value: 3, label: '德华街街道办事处'},
                        {value: 3, label: '嵩山路街道办事处'},
                        {value: 3, label: '长江路街道办事处'},
                        {value: 3, label: '京广路街道办事处'},
                        {value: 3, label: '马寨镇街道办事处'},
                        {value: 3, label: '侯寨乡街道办事处'},

                    ]
                },
                {
                    value: 410104,
                    pid: 410000,
                    label: '管城区人武部',
                    position: '113.677446,34.754436',
                    children: [
                        {value: 3, label: '航海东路街道办事处'},
                        {value: 3, label: '城东路街道办事处'},
                        {value: 3, label: '紫荆山南路街道办事处'},
                        {value: 3, label: '西大街街道办事处'},
                        {value: 3, label: '北下街街道办事处'},
                        {value: 3, label: '陇海马路街道办事处'},
                        {value: 3, label: '二里岗街道办事处'},
                        {value: 3, label: '东大街街道办事处'},
                        {value: 3, label: '南关街街道办事处'},
                        {value: 3, label: '十八里河街道办事处'},
                        {value: 3, label: '曹乡街道办事处'},
                        {value: 3, label: '圃田乡街道办事处'},
                    ]
                },
                {
                    value: 410171,
                    pid: 410000,
                    label: '经开区人武部',
                    position: '113.736185,34.723015',
                    children: []
                },
                {
                    value: 410102,
                    pid: 410000,
                    label: '中原区人武部',
                    position: '113.612966,34.74828',
                    children: [
                        {value: 3, label: '建设路街道办事处'},
                        {value: 3, label: '秦岭路街道办事处'},
                        {value: 3, label: '三官庙街道办事处'},
                        {value: 3, label: '林山寨街道办事处'},
                        {value: 3, label: '桐柏路街道办事处'},
                        {value: 3, label: '东村街道办事处'},
                        {value: 3, label: '棉纺路街道办事处'},
                        {value: 3, label: '航海西路街道办事处'},

                    ]
                },
                {
                    value: 410172,
                    pid: 410000,
                    label: '高新区人武部',
                    position: '113.569743,34.795808',
                    children: []
                },
                {
                    value: 410108,
                    pid: 410000,
                    label: '惠济区人武部',
                    position: '113.617055,34.867994',
                    children: [
                        {value: 3, label: '老鸦陈街道办事处'},
                        {value: 3, label: '长兴路街道办事处'},
                        {value: 3, label: '迎宾路街道办事处'},
                        {value: 3, label: '新城街道办事处'},
                        {value: 3, label: '刘寨街道办事处'},
                        {value: 3, label: '大河路街道办事处'},
                        {value: 3, label: '古荥镇街道办事处'},

                    ]
                },
                {
                    value: 410106,
                    pid: 410000,
                    label: '上街区人武部',
                    position: '113.309185,34.803514',
                    children: []
                },
                {
                    value: 410173,
                    pid: 410000,
                    label: '航空港区人武部',
                    position: '113.847643,34.572488',
                    children: []
                },
                {
                    value: 410122,
                    pid: 410000,
                    label: '中牟县人武部',
                    position: '113.975798,34.720535',
                    children: []
                },
                {
                    value: 410181,
                    pid: 410000,
                    label: '巩义市人武部',
                    position: '113.022354,34.748207',
                    children: []
                },
                {
                    value: 410182,
                    pid: 410000,
                    label: '荥阳市人武部',
                    position: '113.383385,34.7866',
                    children: []
                },
                {
                    value: 410183,
                    pid: 410000,
                    label: '新密市人武部',
                    position: '113.390932,34.540216',
                    children: []
                },
                {
                    value: 410184,
                    pid: 410000,
                    label: '新郑市人武部',
                    position: '113.74024,34.396479',
                    children: []
                },
                {
                    value: 410185,
                    pid: 410000,
                    label: '登封市人武部',
                    position: '113.05056,34.454877',
                    children: []
                }
            ]
        }
    ],
    "zipCode": [
        ["郑州市", "0371", "450000"],
        ["中原区", "0371", "450000"],
        ["二七区", "0371", "450000"],
        ["管城回族区", "0371", "450000"],
        ["金水区", "0371", "450000"],
        ["上街区", "0371", "450041"],
        ["惠济区", "0371", "450000"],
        ["中牟县", "0371", "451450"],
        ["巩义市", "0371", "451200"],
        ["荥阳市", "0371", "450100"],
        ["新密市", "0371", "452370"],
        ["新郑市", "0371", "451100"],
        ["登封市", "0371", "452470"],
        ["开封市", "0378", "475000"],
        ["杞县", "0378", "475200"],
        ["龙亭区", "0378", "475000"],
        ["顺河回族区", "0378", "475000"],
        ["鼓楼区", "0378", "475000"],
        ["禹王台区", "0378", "475000"],
        ["金明区", "0378", "475000"],
        ["通许县", "0378", "475400"],
        ["尉氏县", "0378", "475500"],
        ["开封县", "0378", "475100"],
        ["兰考县", "0378", "475300"],
        ["洛阳市", "0379", "471000"],
        ["老城区", "0379", "471000"],
        ["西工区", "0379", "471000"],
        ["廛河回族区", "0379", "471000"],
        ["涧西区", "0379", "471000"],
        ["吉利区", "0379", "471000"],
        ["洛龙区", "0379", "471000"],
        ["孟津县", "0379", "471100"],
        ["新安县", "0379", "471800"],
        ["栾川县", "0379", "471500"],
        ["汝阳县", "0379", "471200"],
        ["宜阳县", "0379", "471600"],
        ["洛宁县", "0379", "471700"],
        ["伊川县", "0379", "471300"],
        ["偃师市", "0379", "471900"],
        ["偃师区", "0379", "471900"],
        ["平顶山市", "0375", "467143"],
        ["郏县", "0375", "467100"],
        ["新华区", "0375", "467000"],
        ["卫东区", "0375", "467000"],
        ["石龙区", "0375", "467000"],
        ["湛河区", "0375", "467000"],
        ["宝丰县", "0375", "467400"],
        ["叶县", "0375", "467200"],
        ["鲁山县", "0375", "467300"],
        ["舞钢市", "0375", "462500"],
        ["汝州市", "0375", "467500"],
        ["安阳市", "0372", "455000"],
        ["文峰区", "0372", "455000"],
        ["北关区", "0372", "455000"],
        ["殷都区", "0372", "455000"],
        ["龙安区", "0372", "455000"],
        ["安阳县", "0372", "455100"],
        ["汤阴县", "0372", "456150"],
        ["滑县", "0372", "456400"],
        ["内黄县", "0372", "456300"],
        ["林州市", "0372", "456500"],
        ["鹤壁市", "0392", "458000"],
        ["鹤山区", "0392", "458000"],
        ["山城区", "0392", "458000"],
        ["淇滨区", "0392", "458000"],
        ["淇县", "0392", "456750"],
        ["新乡市", "0373", "453000"],
        ["红旗区", "0373", "453000"],
        ["卫滨区", "0373", "453000"],
        ["凤泉区", "0373", " "],
        ["牧野区", "0373", "453002"],
        ["新乡县", "0373", "453700"],
        ["获嘉县", "0373", "453800"],
        ["原阳县", "0373", "453500"],
        ["延津县", "0373", "453200"],
        ["封丘县", "0373", "453300"],
        ["长垣县", "0373", "453400"],
        ["卫辉市", "0373", "453100"],
        ["辉县市", "0373", "453600"],
        ["焦作市", "0391", "454150"],
        ["解放区", "0391", "454150"],
        ["中站区", "0391", "454150"],
        ["马村区", "0391", "454150"],
        ["山阳区", "0391", "454150"],
        ["修武县", "0391", "454350"],
        ["博爱县", "0391", "454450"],
        ["武陟县", "0391", "454950"],
        ["沁阳市", "0391", "454550"],
        ["孟州市", "0391", "454750"],
        ["濮阳市", "0393", "457000"],
        ["华龙区", "0393", "457001"],
        ["清丰县", "0393", "457300"],
        ["南乐县", "0393", "457400"],
        ["范县", "0393", "457500"],
        ["台前县", "0393", "457600"],
        ["濮阳县", "0393", "457001"],
        ["许昌市", "0374", "461000"],
        ["魏都区", "0374", "461000"],
        ["建安区", "0374", "461143"],
        ["许昌县", "0374", "461100"],
        ["鄢陵县", "0374", "461200"],
        ["襄城县", "0374", "452670"],
        ["禹州市", "0374", "452570"],
        ["长葛市", "0374", "461500"],
        ["漯河市", "0395", "462000"],
        ["源汇区", "0395", "462000"],
        ["郾城区", "0395", "462300"],
        ["召陵区", "0395", "462300"],
        ["舞阳县", "0395", "462400"],
        ["临颍县", "0395", "462600"],
        ["三门峡市", "0398", "472000"],
        ["湖滨区", "0398", "472000"],
        ["渑池县", "0398", "472400"],
        ["陕县", "0398", "472100"],
        ["卢氏县", "0398", "472200"], ["义马市", "0398", "472300"], ["灵宝市", "0398", "472500"],
        ["南阳市", "0377", "473000"], ["宛城区", "0377", "473000"], ["卧龙区", "0377", "473000"], ["南召县", "0377", "474650"], ["方城县", "0377", "473200"],
        ["西峡县", "0377", "474550"], ["镇平县", "0377", "474250"], ["内乡县", "0377", "474350"], ["淅川县", "0377", "474450"], ["社旗县", "0377", "473300"],
        ["唐河县", "0377", "473400"], ["新野县", "0377", "473500"], ["桐柏县", "0377", "474750"], ["邓州市", "0377", "474150"], ["商丘市", "0370", "476000"],
        ["梁园区", "0370", "476000"], ["睢阳区", "0370", "476000"], ["民权县", "0370", "476800"], ["宁陵县", "0370", "476700"], ["柘城县", "0370", "476200"],
        ["虞城县", "0370", "476300"], ["夏邑县", "0370", "476400"], ["永城市", "0370", "476600"], ["信阳市", "0376", "464000"], ["师河区", "0376", "464000"],
        ["平桥区", "0376", "464000"], ["罗山县", "0376", "464200"], ["光山县", "0376", "465450"], ["新县", "0376", "465500"], ["商城县", "0376", "465350"],
        ["固始县", "0376", "465200"], ["潢川县", "0376", "465150"], ["淮滨县", "0376", "464400"], ["周口市", "0394", "466000"], ["川汇区", "0394", "466000"],
        ["扶沟县", "0394", "461300"], ["西华县", "0394", "466600"], ["商水县", "0394", "466100"], ["沈丘县", "0394", "466300"], ["郸城县", "0394", "477150"],
        ["淮阳县", "0394", "466700"], ["太康县", "0394", "475400"], ["鹿邑县", "0394", "477200"], ["项城市", "0394", "466200"], ["驻马店市", "0396", "463000"],
        ["驿城区", "0396", "463000"], ["西平县", "0396", "463900"], ["上蔡县", "0396", "463800"], ["平舆县", "0396", "463400"], ["正阳县", "0396", "463600"],
        ["确山县", "0396", "463200"], ["泌阳县", "0396", "463700"], ["汝南县", "0396", "463300"], ["遂平县", "0396", "463100"], ["新蔡县", "0396", "463500"],
        ["济源市", "0391", "454650"], ["邳州市", "", "221300"], ["淮阳区", "0394", "466733"], ["广信区", "", "334100"], ["高密市", "", "261500"],
        ["广信区", "", "334100"], ["高密市", "", "261500"], ["建阳区", "", "354200"], ["红花岗区", "", "563000"], ["宜章县", "", "424200"],["开发区", "", "050000"],
        ["红寺堡区", "", "751999"], ["明水县", "", "151700"], ["蒲城县", "", "715500"], ["温县", "", "454850"], ["东明县", "", "274500"], ["祥符区", "", "475100"],
        ["凯里市", "", "556000"], ["平定县", "", "045200"], ["宜良县", "", "652100"], ["永新县", "", "343400"], ["浏阳市", "", "410300"],
        ["陵城区", "", "253500"], ["长岭县", "", "131500"], ["大祥区", "", "422000"], ["荷塘区", "", "412000"], ["九三管理局", "", "201112"],
        ["桥西区", "", "075061"], ["灵寿县", "", "050500"], ["三台县", "", "621100"], ["丛台区", "", "056004"], ["大同矿区", "", "037000"],["阳泉矿区", "", "045000"],["矿区", "", "037000"], ["浉河区", "", "464000"],
        ["荔波县", "", "558400"], ["浦江镇", "", "201112"], ["睢县", "", "476900"], ["浚县", "", "456250"], ["孟津区", "", "471100"], ["麒麟区", "", "655000"],
        ["耒阳市", "", "421800"], ["商河县", "", "251600"], ["海勃湾区", "", "016000"],  ["故城县", "", "253800"], ["龙泉驿区", "", "610100"], ["雨湖区", "", "411100"],
        ["大名县", "", "056900"], ["鸡冠区", "", "158100"], ["胶州市", "", "266300"], ["陇西县", "", "748100"], ["颍泉区", "", "236000"], ["渭源县", "", "748100"],
        ["陕州区", "", "472100"], ["金盏地区", "", "100018"], ["息县", "", "464300"], ["嵩县", "", "471400"],["辽阳县", "", "111200"],["公主岭市", "", "136105"],["五大连池市", "", "164100"],
        ["惠民县", "", "251700"],["沿河土家族自治县", "", "565309"],["立山区", "", "114000"],["内江市中区", "", "641000"],["市中区", "", "641000"],["平川区", "", "730900"],
        ["静海县梁头镇", "", "301699"],["鸡东县", "", "158200"],["肃宁县", "", "062350"],["枞阳县", "", "246700"],["佳木斯向阳区", "", "154000"],["龙马潭区", "", "646000"],
        ["富锦市", "", "156100"],
    ],
    "location_code": [
        { value: '410102', label: '中原区（高新区）' },
        { value: '410103', label: '二七区' },
        { value: '410104', label: '管城区（经开区）' },
        { value: '410105', label: '金水区（郑东新区）' },
        { value: '410106', label: '上街区' },
        { value: '410108', label: '惠济区' },
        { value: '410122', label: '中牟县' },
        { value: '410181', label: '巩义市' },
        { value: '410182', label: '荥阳市' },
        { value: '410183', label: '新密市' },
        { value: '410184', label: '新郑市（航空港区）' },
        { value: '410185', label: '登封市' },
    ]
};

let _options_map = {
    "nation": {
        "汉族": 1,
        "回族": 2,
        "维吾尔族": 3,
        "苗族": 4,
        "彝族": 5,
        "满族": 6,
        "蒙古族": 7,
        "藏族": 8,
        "壮族": 9,
        "布依族": 10,
        "侗族": 11,
        "瑶族": 12,
        "白族": 13,
        "土家族": 14,
        "哈尼族": 15,
        "哈萨克族": 16,
        "傣族": 17,
        "黎族": 18,
        "傈僳族": 19,
        "佤族": 20,
        "畲族": 21,
        "高山族": 22,
        "拉祜族": 23,
        "水族": 24,
        "东乡族": 25,
        "纳西族": 26,
        "景颇族": 27,
        "柯尔克孜族": 28,
        "土族": 29,
        "达斡尔族": 30,
        "仫佬族": 31,
        "羌族": 32,
        "布朗族": 33,
        "撒拉族": 34,
        "毛南族": 35,
        "仡佬族": 36,
        "基诺族": 37,
        "锡伯族": 38,
        "阿昌族": 39,
        "普米族": 40,
        "朝鲜族": 41,
        "塔吉克族": 42,
        "怒族": 43,
        "乌孜别克族": 44,
        "俄罗斯族": 45,
        "鄂温克族": 46,
        "德昂族": 47,
        "保安族": 48,
        "裕固族": 49,
        "京族": 50,
        "塔塔尔族": 51,
        "独龙族": 52,
        "鄂伦春族": 53,
        "赫哲族": 54,
        "门巴族": 55,
        "珞巴族": 56,
        "未录入": 57,
        "其他民族": 58,
        "穿青人": 58,
        "僜人": 58
    },
    "sex": {
        "男": 1,
        "女": 2
    },
    "house": {
        "城镇居民": 1,
        "农村居民": 2
    },
    "eduLevelKind": {
        "博士": 1,
        "研究生": 1,
        "硕士": 1,
        "硕士研究生": 1,
        "一本": 2,
        "二本": 3,
        "三本": 4,
        "本科": 5,
        "高校新生": 6,
        "预科": 7,
        "本科预科": 8,
        "专升本": 9,
        "大专": 10,
        "专科": 11,
        "普通高中": 12,
        "高中": 12,
        "中专": 13,
        "中职": 13,
        "中等职业": 13,
        "初中": 14,
        "小学及以下": 15
    },
    "eduLevel": {
        "博士": 1,
        "研究生": 2,
        "硕士": 3,
        "硕士研究生": 4,
        "一本": 5,
        "二本": 6,
        "三本": 7,
        "本科": 8,
        "大学本科": 8,
        "高校新生": 9,
        "预科": 10,
        "本科预科": 11,
        "专升本": 12,
        "大专": 13,
        "大专应届": 13,
        "专科": 14,
        "普通高中": 15,
        "高中": 16,
        "中专": 17,
        "中职": 18,
        "中等职业": 19,
        "中等职业学校": 19,
        "初中": 20,
        "小学及以下": 21
    },
    "studies": {
        "高校新生": 1,
        "应届毕业": 2,
        "往届毕业": 3,
        "往届毕业生": 4,
        "非在校生": 5,
        "在校生": 6,
        "学生": 7,
        "初中": 8,
        "小学及以下": 9,
        "毕业班生": 10
    },
    "studiesKind": {
        "高校新生": 1,
        "应届毕业": 2,
        "往届毕业": 3,
        "往届毕业生": 3,
        "非在校生": 3,
        "在校生": 4,
        "学生": 4,
        "初中": 5,
        "小学及以下": 6,
        "毕业班生": 7
    },
    "checkEdu": {
        "未通过": 1,
        "通过": 2
    },
    "politics": {
        "中共党员": 1,
        "中共预备党员": 2,
        "共青团员": 3,
        "群众": 4,
        "其他党派成员": 5,
        "无党派民主人士": 6
    },
    "married": {
        "未婚": 1,
        "已婚": 2,
        "其他": 3,
        "离异": 4
    },
    "onlyChild": {
        "非独生子女": 1,
        "独生子女": 2
    },
    "studyType": {
        "全日制": 1,
        "普通全日制": 2,
        "普通高等教育": 3,
        "普通专科学生": 4,
        "非全日制": 5,
        "成人教育": 6,
        "开放教育": 7,
        "网络教育": 8,
        "自考": 9,
        "业余": 10
    },
    "studyTypeKind": {
        "全日制": 1,
        "普通全日制": 1,
        "普通高等教育": 1,
        "普通专科学生": 1,
        "非全日制": 2,
        "成人教育": 2,
        "开放教育": 2,
        "网络教育": 2,
        "自考": 2,
        "业余": 2
    },
    "verification": {
        "未通过": 1,
        "通过": 2,
        "待核检": 3
    },
    "inspect": {
        "否": 1,
        "是": 2,
        "未审核": 3
    },
    "physical": {
        "待录入": 1,
        "合格": 2
    },
    "political": {
        "待录入": 1
    },
    "initial": {
        "待审核": 1
    },
    "tiJianState": {
        "待定": 1,
        "审核完成": 2,
        "未体检": 3,
        "已检查": 4,
        "体检完成": 5,
        "已放弃": 6
    },
    "tiJianStr": {
        "不合格": 1,
        "合格": 2
    },
    "politicalResults": {
        "通过": 1,
        "未通过": 2,
        "合格": 1,
        "不合格": 2
    },
    "tiJianResult": {
        "不合格": 1,
        "合格": 2
    },
    "volunteer_1": {
        "陆军": 1,
        "海军": 2,
        "空军": 3,
        "火箭军": 4,
        "战略支援部队": 5,
        "联勤保障部队": 8,
        "武警": 6,
        "仪仗队": 7
    },
    "volunteer_2": {
        "陆军": 1,
        "海军": 2,
        "空军": 3,
        "火箭军": 4,
        "战略支援部队": 5,
        "联勤保障部队": 8,
        "武警": 6,
        "仪仗队": 7
    }
}

for(let key in _options_map){
    let arr = []
    for(let k in _options_map[key]){
        arr.push({"label": k, "value": _options_map[key][k], "checked": 0})
    }
    _OPTIONS[key] = arr
}

export {
    _OPTIONS
}