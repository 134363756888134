import { createRouter, createWebHashHistory } from 'vue-router'
import open from '@/layout/open'
import platform from '@/layout/platform'
import emptyPrint from '@/layout/emptyPrint'

const openRoutes = [
    {
        path: '/login',
        name: 'login',
        redirect: '/login',
        component: open,
        meta: {title: '登录', classify: 'login', icon: 'login', affix: true, isShow: 0, table:''},
        children: [
            {
                path: '/login',
                component: () => import('@/views/login/index'),
                name: 'login',
                meta: {title: '登录', classify: 'login', icon: 'login', affix: true, isShow: 0, table:''}
            }
        ]
    },
    {
        path: '/printDetails',
        component: emptyPrint,
        redirect: '/printDetails',
        name: "printDetails",
        meta: { title: '打印详情', classify: 'printDetails', icon: 'printDetails', affix: true, isShow: 0 },
        children: [
            {
                path: '/printDetails/:id',
                component: () => import('@/views/printDetails/index'),
                name: 'printTable',
                meta: { title: '打印详情', classify: 'printDetails', icon: 'printDetails', affix: true, isShow: 0 }
            }
        ]
    },
]

const homeRoutes = filterByKey([
    {
        path: '/',
        component: platform,
        redirect: '/home',
        name: "home",
        meta: {title: '首页', classify: 'home', icon: 'home', affix: true, level: 5, isShow: 1,authClassify:"home", table:''},
        children: [
            {
                path: '/home',
                component: () => import('@/views/home/index'),
                name: 'homeIndex',
                meta: {title: '首页', classify: 'home', icon: 'home', affix: true, level: 5, isShow: 1,authClassify:"home", table:''}
            }
        ]
    },
])

const printRoutes = filterByKey([
    {
        path: '/print',
        component: platform,
        redirect: '/print',
        name: "print",
        meta: {title: '数据打印', classify: 'print', icon: 'print', affix: true, level: 5, isShow: 1,authClassify:"print", table:''},
        children: [
            {
                path: '/print',
                component: () => import('@/views/print/index'),
                name: 'printIndex',
                meta: {title: '打印', classify: 'print', icon: 'print', affix: true, level: 5, isShow: 1,authClassify:"print", table:''}
            },
            {
                path: '/upload',
                component: () => import('@/views/print/upload'),
                name: 'uploadIndex',
                meta: {title: '导入数据', classify: 'print', icon: 'print', affix: true, level: 5, isShow: 1,authClassify:"print", table:''}
            }
        ]
    },
])

const systemRoutes = filterByKey([
    {
        path: '/system',
        component: platform,
        redirect: '/system/index',
        name: "system",
        meta: {title: '系统配置', classify: 'system', icon: 'system', affix: true, level: 2, role: 2, isShow: 1},
        children: filterByKey([
            {
                path: 'index',
                component: () => import('@/views/system/index'),
                name: 'systemIndex',
                meta: {title: '系统配置', classify: 'system', icon: 'system', affix: true, level: 2, role: 2, isShow: 1}
            },
        ])
    }
])

const constantRoutes = [
    ...openRoutes,
    ...homeRoutes,
    ...printRoutes,
    ...systemRoutes
]

const router = createRouter({
  history: createWebHashHistory(),
  routes:constantRoutes
})

//TODO: 先实现下一级的过滤，
function filterByKey(arrayObj) {
    let index = arrayObj.findIndex((item) => {
        return item["meta"]["isShow"] === 0
    })
    if (index > -1) {
        arrayObj.splice(index, 1);
    }
    return arrayObj
}


export {
    homeRoutes,
    printRoutes,
    systemRoutes,
    router
}
