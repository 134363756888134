<template>
  <div class="site-header">
    <div class="start">
      <span class="unit-name">{{ systemConfig.name }}</span>
      <span class="em" style="vertical-align: bottom">【{{env}}】</span>
    </div>
    <div class="end">
      <span class="user-name">HI，{{ userInfo.realname||userInfo.nickname||userInfo.username }}</span>
      <span class="switch-user" @click="logout">退出用户</span>
    </div>
  </div>
</template>

<script>
import {_data, _methods, util} from '../../common/public.js'

export default {
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ..._data,
      systemConfig:{},
      userInfo: {realname: '',nickname:'',username:''},
      env:"",
    }
  },
  created() {
    this.systemConfig = util.getLocalStorage('systemConfig',{})
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log('this.userInfo',this.userInfo,this.systemConfig)

    this.getEnv()
  },
  methods: {
    ..._methods,
    logout() {
      localStorage.setItem('TOKEN', '');
      localStorage.setItem('userInfo', '');
      this.$router.push('Login');
    },
    getEnv(){
      if(location.port===""||location.port==="80"){
        if(location.host==="fl-boss.effmaster.com"){
          this.env = "正式环境"
        }else{
          this.env = "测试环境"
        }
      }else{
        this.env = "本地环境"
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.site-header {
  display: flex;
  background: #fff;
  height: 70px;
  margin-bottom: 16px;
}

.site-header-placeholder {
  height: 70px;
  margin-bottom: 16px;
}

.site-header .start {
  width: 50%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.site-header .start .unit-name {
  font-size: 20px;
}

.site-header .end {
  flex: 1;
  text-align: right;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
}

.site-header .end span {
  margin-left: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}

.site-header .switch-user {
  cursor: pointer;
}

.site-header .switch-user:hover {
}
</style>
