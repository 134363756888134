<template>
    <div class="print-body" id="wrap">
        <router-view/>
    </div>
    <div class="watermark" v-if="CONFIG.isUse">
        <span v-for="item in 20" :key='item'>{{ CONFIG.short_name||'' }}--{{userInfo.username}}</span>
    </div>
</template>

<script>
    import {_CONFIG} from '../common/public.js'
    export default {
        data() {
            return {
                CONFIG:_CONFIG,
                userInfo:{username:''}
            }
        },
        created() {
            if(localStorage.getItem('userInfo')){
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
            }
        },
        mounted() {
            document.getElementById("body").className = "body-print"
        },
    }
</script>

<style lang="scss" scoped>
    .print-body{
        width: 100%;
        display: flex;
        justify-content: center;

        position: relative;
        z-index: 10;
    }
</style>