<template>
  <div class="sidebar" id="sidebar">
    <div class="site-name" data-path="/" @click="_jump">{{ systemConfig.name }}</div>

    <template v-if="homeRoutes.length">
      <ul class="main-nav">
        <li v-for="(item,index) in homeRoutes" :key="index">
          <router-link :to="{name:item.name}">{{ item.meta.title }}</router-link>
        </li>
      </ul>
    </template>

    <template v-if="printRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item,index) in printRoutes" :key="index">
          <router-link :to="{name:item.name}">{{ item.meta.title }}</router-link>
        </li>
      </ul>
    </template>

    <template v-if="systemRoutes.length">
      <div class="hr"></div>
      <ul class="main-nav">
        <li v-for="(item,index) in systemRoutes" :key="index">
          <router-link :to="{name:item.name}">{{ item.meta.title }}</router-link>
        </li>
      </ul>
    </template>

  </div>
</template>

<script>
import {
    homeRoutes,
    printRoutes,
    systemRoutes
} from '../../router'
import {_data, _methods, util} from '../../common/public'

export default {
  name: 'PageSidebar',
  data() {
    return {
      ..._data,
      routes: [],
        homeRoutes: homeRoutes,
        printRoutes: printRoutes,
        systemRoutes:systemRoutes,
      curRouteName: '',
      systemConfig: {},
      userInfo: {level: 1},
    }
  },
  created() {
    console.log('this.$route', this.$route)
    this.curRouteName = this.$route.name
    this.systemConfig = util.getLocalStorage('systemConfig', {})
      console.log('dd',this.systemConfig)
    this.userInfo = util.getLocalStorage('userInfo', {})
  },
  methods: {
    ..._methods,
    _jump(e) {
      let {path} = e.target.dataset;
      this.$router.push({
        path: path
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 200px;
  background: #44326a;
  color: #fff;
  font-size: 16px;
  overflow-y: auto;
}

.site-name {
  height: 70px;
  font-size: 26px;
  padding-left: 24px;
  margin-bottom: 8px;
  border-bottom: #302656 solid 1px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.sidebar {
  .sub-title {
    padding: 0 14px 10px;
    line-height: 1.5;
    border-left: #503a7d solid 2px;
    font-size: 18px;
  }

  .sub-title .small {
    font-size: 13px;
  }

  .hr {
    margin-top: 6px;
    margin-bottom: 6px;
    border-bottom: #302656 solid 1px;
  }

  a {
    font-size: 16px;
    color: #fff;
    display: block;
    line-height: 38px;
    padding: 0 20px;
    border-left: transparent solid 4px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s;
    text-align: left;

    &:hover {
      background: #654b93;
    }
  }
}

.sidebar a.router-link-active {
  background: #654b93;
  border-left-color: #503a7d;
}

.sidebar a.router-link-active:hover {
  background: #654b93;
}

.selectYear {
  padding: 8px;
  margin-bottom: 8px;
}
</style>